




















































































import { Component, Prop, Vue } from "vue-property-decorator";
import SubNavVue from "@/components/common/subNav/SubNav.vue";

@Component({
  components: {
    "sub-nav": SubNavVue,
  },
})
export default class AfterSaleVue extends Vue {
  linkData: Array<any> = [
    {
      name: "首页",
      link: "/",
    },
    {
      name: "售后服务",
      link: "#",
    },
  ];
}
