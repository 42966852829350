import { render, staticRenderFns } from "./Qa.vue?vue&type=template&id=58f5cd79&scoped=true&"
import script from "./Qa.vue?vue&type=script&lang=ts&"
export * from "./Qa.vue?vue&type=script&lang=ts&"
import style0 from "./qa.css?vue&type=style&index=0&id=58f5cd79&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58f5cd79",
  null
  
)

export default component.exports