












































































import { Component, Prop, Vue } from "vue-property-decorator";
import SubNavVue from "@/components/common/subNav/SubNav.vue";

@Component({
  components: {
    "sub-nav": SubNavVue,
  },
})
export default class PsActivityVue extends Vue {
  subData: Array<any> = [
    {
      name: "首页",
      link: "/",
    },
    {
      name: "教育方案",
      link: "#",
    },
    {
      name: "科普活动",
      link: "#",
    },
  ];
}
