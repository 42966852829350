import { Component, Prop, Vue } from "vue-property-decorator";
import { HttpHelper } from '../../helper/httpHelper';
import { AxiosResponse } from 'axios';

@Component({})
export default class FootVue extends Vue {
   
    email:string = '' ;

    modules:Array<any> = [

        {  
            label:'关于我们',
            list:[
                {
                    name:'公司简介', 
                    link:'/aboutUs'
                },
                // {
                //     name:'Makerfire', 
                //     link:'http://www.makerfire.cn/',
                //     out:true
                // },
                // {
                //     name:'Crazepony', 
                //     link:'http://www.crazepony.com/',
                //     out:true
                // }
            ]
        },
        {  
            label:'新闻中心',
            list:[
                {
                    name:'媒体报道', 
                    link:'/news/list'
                }, 
            ]
        },
        {  
            label:'服务与支持',
            list:[
                {
                    name:' 售后服务', 
                    link:'/afterSale'
                },
                {
                    name:'申请成为经销商', 
                    out:true ,
                    link:'http://jsform3.com/web/formview/5e1e7d1775a03c25df286b48'
                }  
            ]
        },
        {  
            label:'战略合作',
            list:[
                {
                    name:' AOPA梦天计划', 
                    link:'https://cn.litebee.com/aopa.html',
                    out:true 
                } 
            ]
        }

    ];

    /** 是否展示订单成功窗口 */
    showSubSuccess: boolean = false ;


    /** 订阅 */
    subscribe(){ 
        if(!this.email) {
            alert('请输入邮箱信息');
            return ;
        }
        HttpHelper.subByEmail(this.email).then((result: AxiosResponse)=>{
            if(result.data.code == 0 ) {
                this.showSubSuccess = true ;
            } 
            else {
                alert(result.data.msg) ;
            }
        });
    }
    
    mounted() { 
    }
}