









import { Component, Prop, Vue } from "vue-property-decorator";
import QaVue from "../qa/Qa.vue";
import VideoVue from "../video/Video.vue";
import DownloadVue from "../download/Download.vue";
import { RouterHelper } from "@/router/routerHelper";

@Component({
  components: {
    download: DownloadVue,
  },
})
export default class LiteBeeSkyDownloadVue extends Vue {
  prodCode: string = "liteBeeSky";
  prodName: string = "LiteBee Sky";
  tabList: Array<any> = [];

  linkData: Array<any> = [
    { name: "首页", link: "/" },
    { name: this.prodName, link: "/product/liteBeeSky" },
    { name: "软件下载", link: "#" },
  ];
  mounted() {
    RouterHelper.getProdRouterConfig(this.prodCode, (data: Array<any>) => {
      this.tabList = data;
    });
  }
}
