import { Component, Prop, Vue } from 'vue-property-decorator';
import NavHeaderVue from '../components/common/navHeader/NavHeader.vue';
import FootVue from '../components/foot/Foot.vue';



@Component({
    components: {
        'nav-header': NavHeaderVue,
        'foot': FootVue
    }
})
export default class AppVue extends Vue {

    /** 返回顶部 */
    go2Top() {
        document.body.scrollIntoView({
            block: 'start',
            behavior: 'smooth'
        }) ;
        // console.log(document.body.scroll)
        // document.body.scroll({
        //     top: 0,
        //     left: 0,
        //     behavior: 'smooth'
        // });
    }
} 