









import { Component, Prop, Vue } from "vue-property-decorator";
import DownloadVue from "../download/Download.vue";
import { RouterHelper } from "@/router/routerHelper";

@Component({
  components: {
    download: DownloadVue,
  },
})
export default class simulator181DownloadVue extends Vue {
  prodName: string = "181模拟器";
  prodCode: string = "simulator181";
  tabList: Array<any> = [];

  mounted() {
    RouterHelper.getProdRouterConfig(this.prodCode, (data: Array<any>) => {
      this.tabList = data;
    });
  }
}
