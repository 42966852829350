



























































































import { Component, Prop, Vue } from "vue-property-decorator";
import SubNavVue from "@/components/common/subNav/SubNav.vue";

@Component({
  components: {
    "sub-nav": SubNavVue,
  },
})
export default class DownloadVue extends Vue {
  subData: Array<any> = [
    {
      name: "首页",
      link: "/",
    },
    {
      name: "服务与支持",
      link: "#",
    },
    {
      name: "软件下载",
      link: "#",
    },
  ];

  list: Array<any> = [
    {
      name: "LiteBee Wing Fm",
      link: "/product/liteBeeWingFm/download",
      icon: "https://cn.litebee.com/assets/local/download/icon_1.png",
    },
    {
      name: "LiteBee Sky",
      link: "/product/liteBeeSky/download",
      icon: "https://cn.litebee.com/assets/local/download/icon_2.png",
    },
    {
      name: "LiteBee Wing",
      link: "/product/liteBeeWing/download",
      icon: "https://cn.litebee.com/assets/local/download/icon_3.png",
    },
    {
      name: "GhostII",
      link: "/product/ghostII/download",
      icon: "https://cn.litebee.com/assets/local/download/icon_4.png",
    },
    {
      name: "Drone:bit",
      link: "/product/droneBit/download",
      icon: "https://cn.litebee.com/assets/local/download/icon_6.png",
    },
    {
      name: "181模拟器",
      link: "/product/simulator181/download",
      icon: "https://cn.litebee.com/assets/local/download/icon_5.png",
    },
  ];
}
