









import { Component, Prop, Vue } from "vue-property-decorator";
import QaVue from "../qa/Qa.vue";
import VideoVue from "../video/Video.vue";
import { RouterHelper } from "@/router/routerHelper";

@Component({
  components: {
    video_: VideoVue,
  },
})
export default class LiteBeeSkyVideoVue extends Vue {
  prodName: string = "LiteBee Sky";
  prodCode: string = "liteBeeSky";
  tabList: Array<any> = [];

  linkData: Array<any> = [
    { name: "首页", link: "/" },
    { name: this.prodName, link: "/product/liteBeeSky" },
    { name: "教学视频", link: "#" },
  ];

  mounted() {
    RouterHelper.getProdRouterConfig(this.prodCode, (data: Array<any>) => {
      this.tabList = data;
    });
  }
}
