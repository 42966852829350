import { render, staticRenderFns } from "./Course2.vue?vue&type=template&id=2981c390&scoped=true&"
import script from "./Course2.vue?vue&type=script&lang=ts&"
export * from "./Course2.vue?vue&type=script&lang=ts&"
import style0 from "@/assets/css/course.css?vue&type=style&index=0&id=2981c390&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2981c390",
  null
  
)

export default component.exports