









































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class SubNav2Vue extends Vue {
  @Prop({
    type: Array,
    default: [],
  })
  linkData?: Array<any>;

  @Prop({
    type: Number,
    default: 0,
  })
  tabIndex?: number;
}
