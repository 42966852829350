import Vue from 'vue';
// import router from './router';
import store from './store';
import AppVue from './app/App.vue';
import './assets/css/reset.css';
import './assets/css/common.css';
import 'swiper/css/swiper.css'; 


import router from './router/index';
import { ConfigMgr } from './config';
import RouterConfig from './router/config'; 
Vue.use(require('vue-awesome-swiper'));
Vue.config.productionTip = false; 

router.beforeEach((to, from, next) => {


  /* 路由发生变化修改页面meta */
  var meta = to.meta || {};
  meta.content = meta.content || {};

  var title: string = meta.title || RouterConfig.defaultTitle;
  var keywords: string = meta.content.keywords || RouterConfig.defaultKeywords;
  var description: string = meta.content.description || RouterConfig.defaultDesc;

  document.title = title;
  var metaKeywords: any = document.querySelector('meta[name="keywords"]');
  var metaDescription: any = document.querySelector('meta[name="description"]');
  if (metaKeywords) {
    metaKeywords.setAttribute('content', keywords);
  }
  if (metaDescription) {
    metaDescription.setAttribute('content', description);
  }

  next();
});


router.afterEach((to: any) => {
  if (to.path.indexOf('/edu/course') >= 0 || to.path.indexOf('/newsMore') >= 0) {

  }
  else {
    window.scrollTo(0, 0);
  }
})

new Vue({
  router,
  store,
  render: (h) => h(AppVue),
  mounted() {
    document.dispatchEvent(new Event('render-event')); 
  }
}).$mount('#app');
