



























































































import { Component, Prop, Vue } from "vue-property-decorator";
import ProduceNavVue from "@/components/product/ProductNav.vue";
import SubNavVue from "@/components/common/subNav/SubNav.vue";
import { HttpHelper } from "@/helper/httpHelper";
import axios, { AxiosResponse } from "axios";
import { CommonHelper } from '@/helper/commonHelper';

@Component({
  components: {
    "product-nav": ProduceNavVue,
    "sub-nav": SubNavVue,
  },
})
export default class DownloadVue extends Vue {
  /** 产品名 */
  @Prop({
    type: String,
    default: "",
  })
  prodName?: string;

  @Prop({
    type: Array,
    default: [],
  })
  tabList?: Array<any>;

  @Prop({
    type: String,
    default: "",
  })
  prodCode?: string;

  /** 导航信息 */
  @Prop({
    type: Array,
    default: [],
  })
  linkData?: Array<any>;

  /** app数据  */
  appData: any = {
    android: {
      url:'',
    },
    ios: {
      url:'',
    },
  };

  /** 软件数据  */
  software: Array<any> = [];

  /** 文档数据  */
  docList: Array<any> = [];

  mounted() {
    this.$nextTick(() => {
      HttpHelper.getProdDownloadJson(this.prodCode || "").then(
        (axios: AxiosResponse) => {
          this.appData = axios.data.app;
          this.software = axios.data.software;
          this.docList = axios.data.doc; 
        }
      );
    });
  }

  downloadFile(url:string) {
    CommonHelper.downloadFile(url) ;
  }
}
