import { Component, Prop, Vue } from "vue-property-decorator";
import GlobalHelper from '../../../helper/resizeHelper';
import ResizeHelper from '../../../helper/resizeHelper';
import { HttpHelper } from '../../../helper/httpHelper';
import axios from 'axios';
import { AxiosResponse } from 'axios';
import { ConfigMgr } from '../../../config';

@Component({})
export default class NavHeaderVue extends Vue {

    langUrl: string = ConfigMgr.URL_EN;

    /** 产品中心的x坐标 */
    prodCenterLeft: number = 0;

    tabList: Array<any> = [
        { label: "首页", link: '/' },
        { label: "产品中心", isProdCenter: true, link: '#' },
        { label: "教育方案", isEdu: true, link: '#' },
        { label: "赛事体系", link: '/match' },
        { label: "服务与支持", link: '#', isHelp: true },
        { label: "关于我们", link: '/aboutUs', isAboutUs: true },
    ];

    prodData: Array<any> = [];

    eduData: Array<any> = [
        {
            label: '实验室建设',
            icon: 'https://cn.litebee.com/assets/local/edu/icon_1.png',
            iconActive: 'https://cn.litebee.com/assets/local/edu/icon_1_active.png',
            link: '/edu/labBuild',
        },
        {
            label: '课程体系',
            icon: 'https://cn.litebee.com/assets/local/edu/icon_2.png',
            iconActive: 'https://cn.litebee.com/assets/local/edu/icon_2_active.png',
            link: '/edu/TechCourse',
        },
        {
            label: '科普活动',
            icon: 'https://cn.litebee.com/assets/local/edu/icon_3.png',
            iconActive: 'https://cn.litebee.com/assets/local/edu/icon_3_active.png',
            link: '/edu/psActivity',
        }
    ];



    mounted() {


        HttpHelper.getJsonData('prod').then((axios: AxiosResponse) => {
            var data: any = axios.data;

            data.liteBeeBrix = data.liteBeeBrix || {} ;
            data.liteBeeWing = data.liteBeeWing || {} ;
            data.ghostII = data.ghostII || {} ;
            data.liteBeeWingFm = data.liteBeeWingFm || {} ;
            data.liteBeePro = data.liteBeePro || {} ;
            data.c07 = data.c07 || {};
            data.liteBeeBrix3 = data.liteBeeBrix3 || {};
            data.droneBit = data.droneBit || {};
            data.armor90 = data.armor90 || {};
            data.liteBeeStars = data.liteBeeStars || {};
            data.liteBeeSky = data.liteBeeSky || {};

            this.prodData = [
                {
                    label: 'STEAM教育套件',
                    prodList: [
                        {
                            icon: data.liteBeeBrix.icon,
                            name: 'LiteBee Brix',
                            link: '/product/liteBeeBrix'
                        },
                        {
                            icon: data.liteBeeWing.icon,
                            name: 'LiteBee Wing',
                            link: '/product/liteBeeWing'
                        },
                        {
                            icon: data.ghostII.icon,
                            name: 'Ghost II',
                            link: '/product/ghostII'
                        },
                        {
                            icon: data.liteBeeWingFm.icon,
                            name: 'LiteBee Wing FM',
                            link: '/product/liteBeeWingFm'
                        },
                        {
                            icon: data.liteBeeBrix3.icon,
                            name: 'LiteBee Brix III',
                            link: '/product/liteBeeBrix3'
                        },
                        {
                            icon: data.droneBit.icon,
                            name: 'Drone:bit',
                            link: '/product/droneBit'
                        },
                        {
                            icon: data.liteBeePro.icon,
                            name: 'LiteBee Pro',
                            link: '/product/liteBeePro'
                        },
                        {
                            icon: data.liteBeeStars.icon,
                            name: 'LiteBee Stars',
                            link: '/product/liteBeeStars'
                        },
                        {
                            icon: data.liteBeeSky.icon,
                            name: 'LiteBee Sky',
                            link: '/product/liteBeeSky'
                        },
                    ]
                },
                {
                    label: '竞速赛事套件',
                    prodList: [
                        {
                            icon: data.armorWasp.icon,
                            name: 'Armor WASP',
                            link: '/product/armorWasp'
                        },
                        {
                            icon: data.armor90.icon,
                            name: 'Armor 90',
                            link: '/product/armor90'
                        }
                    ]
                }
            ];





        });

        this.$nextTick(() => {
            ResizeHelper.instance.addOnResizeHandle(this.updateProdLeft);
            this.updateProdLeft();
        });
    }

    updateProdLeft() {
        var prodCenterLi: any = this.$refs['prodCenterLi'];
        if (prodCenterLi && prodCenterLi[0]) {
            this.prodCenterLeft = -prodCenterLi[0].offsetLeft;
        }
    }


    /** 保存跳往的语言站点 */
    saveJumpLang() {
        ConfigMgr.saveLastJumpLang();
    }

}