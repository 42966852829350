
import MainVue from '../views/main/Main.vue';
import AboutUsVue from '../views/aboutUs/AboutUs.vue';

import LabBuildVue from '../views/edu/labBuild/LabBuild.vue';
import Course1Vue from '../views/edu/course/Course1.vue';
import Course2Vue from '../views/edu/course/Course2.vue';
import Course3Vue from '../views/edu/course/Course3.vue';
import Course4Vue from '../views/edu/course/Course4.vue';
import Course5Vue from '../views/edu/course/Course5.vue';
import TechCourse from '../views/edu/course/TechCourse.vue';
import PsActivityVue from '../views/edu/psActivity/PsActivity.vue';
import NewsListVue from '../views/news/newsList/NewsList.vue';
import NewsDetailVue from '../views/news/detail/newsDetail.vue';
import AfterSaleVue from '../views/afterSale/AfterSale.vue';
import DownloadVue from '../views/download/Download.vue';
import MatchVue from '../views/match/Match.vue';
import LiteBeeWingFmVue from '../views/product/liteBeeWingFm/Index.vue';
import LiteBeeWingFmQaVue from '../views/product/liteBeeWingFm/qa.vue';
import LiteBeeWingFmVideoVue from '../views/product/liteBeeWingFm/video.vue';
import LiteBeeWingFmDownloadVue from '../views/product/liteBeeWingFm/download.vue';
import GhostIIVue from '../views/product/ghostII/Index.vue';
import LiteBeeWingVue from '../views/product/liteBeeWing/Index.vue';
import LiteBeeBrixVue from '../views/product/liteBeeBrix/Index.vue';
import LiteBeeBrix3Vue from '../views/product/liteBeeBrix3/Index.vue';
import LiteBeeBrix3QaVue from '../views/product/liteBeeBrix3/qa.vue';
import LiteBeeBrix3VideoVue from '../views/product/liteBeeBrix3/video.vue';
import LiteBeeBrix3DownloadVue from '../views/product/liteBeeBrix3/download.vue';
import Armor90Vue from '../views/product/armor90/Index.vue';
import Armor90QaVue from '../views/product/armor90/qa.vue';
import Armor90VideoVue from '../views/product/armor90/video.vue';
import Armor90DownloadVue from '../views/product/armor90/download.vue';
import LiteBeeStarsVue from '../views/product/liteBeeStars/Index.vue';
import LiteBeeStarsQaVue from '../views/product/liteBeeStars/qa.vue';
import LiteBeeStarsVideoVue from '../views/product/liteBeeStars/video.vue';
import LiteBeeStarsDownloadVue from '../views/product/liteBeeStars/download.vue';
import LiteBeeSkyVue from '../views/product/liteBeeSky/Index.vue';
import LiteBeeSkyQaVue from '../views/product/liteBeeSky/qa.vue';
import LiteBeeSkyVideoVue from '../views/product/liteBeeSky/video.vue';
import LiteBeeSkyDownloadVue from '../views/product/liteBeeSky/download.vue';
import Simulator181DownloadVue from '../views/product/simulator181/download.vue';
import DroneBitVue from '../views/product/droneBit/Index.vue';
import DroneBitQaVue from '../views/product/droneBit/qa.vue';
import DroneBitVideoVue from '../views/product/droneBit/video.vue';
import DroneBitDownloadVue from '../views/product/droneBit/download.vue';
import ArmorWaspVue from '../views/product/armorWasp/Index.vue';
import LiteBeeWingQaVue from '../views/product/liteBeeWing/qa.vue';
import LiteBeeWingDownloadVue from '../views/product/liteBeeWing/download.vue';
import LiteBeeWingVideoVue from '../views/product/liteBeeWing/video.vue';
import GhostIIQaVue from '../views/product/ghostII/qa.vue';
import GhostIIDownloadVue from '../views/product/ghostII/download.vue';
import GhostIIVideoVue from '../views/product/ghostII/video.vue';
import ArmorWaspQaVue from '../views/product/armorWasp/qa.vue';
import ArmorWaspDownloadVue from '../views/product/armorWasp/download.vue';
import ArmorWaspVideoVue from '../views/product/armorWasp/video.vue';
import LiteBeeBrixQaVue from '../views/product/liteBeeBrix/qa.vue';
import LiteBeeBrixVideoVue from '../views/product/liteBeeBrix/video.vue'; 
import NewsListMoreVue from '../views/news/newsList/NewsListMore.vue';
import LiteBeeBrixDownloadVue from '../views/product/liteBeeBrix/download.vue';
import AopaVue from '../views/aopa/Aopa.vue';
import LiteBeeProVue from '../views/product/liteBeePro/Index.vue';
import LiteBeeProDownloadVue from '../views/product/liteBeePro/download.vue';
import LiteBeeProVideoVue from '../views/product/liteBeePro/video.vue';
import LiteBeeProQaVue from '../views/product/liteBeePro/qa.vue';



/** 路由配置 */
export default class RouterConfig {

 
  static defaultTitle:string = 'Litebee网站 | 创客教育无人机 | 无人机解决方案供应商';
  static defaultDesc:string = 'LiteBee是中国领先的无人机创客教育品牌，专注于普及化创客教育人才，已技术为核心研发生产无人机系列产品提供中小学创客教育解决方案，推进无人机创客教育的发展';
  static defaultKeywords:string = '创客教育,无人机编程赛,无人机编程,无人机竞赛,四轴飞行器积木无人机,无人机比赛,编程无人机,无人机创客教育解决方案,低空无人机编队,积木无人机';


  static getAllPage(): Array<string> {
    var list: Array<string> = [];
    this.alls.forEach((item: any) => {
      list.push(item.path);
    });
    return list;
  }

  static alls: Array<any> = [  
    {
      path: '/aboutUs',
      name: 'aboutUs',
      component: AboutUsVue,
      
    },

    {
      path: '/product/liteBeePro',
      name: 'liteBeePro',
      component: LiteBeeProVue,
    },
    {
      path: '/product/liteBeePro/qa',
      name: 'liteBeeProQa',
      component: LiteBeeProQaVue,
    },
    {
      path: '/product/liteBeePro/video',
      name: 'liteBeeProVideo',
      component: LiteBeeProVideoVue,
    },
    {
      path: '/product/liteBeePro/download',
      name: 'liteBeeProDownload',
      component: LiteBeeProDownloadVue,
    },


    {
      path: '/product/liteBeeWingFm',
      name: 'liteBeeWingFm',
      component: LiteBeeWingFmVue,
      meta: {
        title: '积木无人机编队套装LiteBee Wing FM-创客火无人机',
        content: {
          keywords: '室内无人机编队，编程无人机，图形化编程，无人机整体解决方案，多机无人机表演',
          description: 'LiteBee Wing FM是一套集合4/10台的无人机室内编队飞行套装，主要面向8-16岁中小学生，可进行多机编队飞行表演，也可应用于多机编程竞赛。',
        },
      }
    },
    {
      path: '/product/liteBeeWingFm/qa',
      name: 'liteBeeWingFmQa',
      component: LiteBeeWingFmQaVue,
      meta: {
        title: '积木无人机编队套装LiteBee Wing FM-创客火无人机',
        content: {
          keywords: '室内无人机编队，编程无人机，图形化编程，无人机整体解决方案，多机无人机表演',
          description: 'LiteBee Wing FM是一套集合4/10台的无人机室内编队飞行套装，主要面向8-16岁中小学生，可进行多机编队飞行表演，也可应用于多机编程竞赛。',
        },
      }
    },
    {
      path: '/product/liteBeeWingFm/video',
      name: 'liteBeeWingFmVideo',
      component: LiteBeeWingFmVideoVue,
      meta: {
        title: '积木无人机编队套装LiteBee Wing FM-创客火无人机',
        content: {
          keywords: '室内无人机编队，编程无人机，图形化编程，无人机整体解决方案，多机无人机表演',
          description: 'LiteBee Wing FM是一套集合4/10台的无人机室内编队飞行套装，主要面向8-16岁中小学生，可进行多机编队飞行表演，也可应用于多机编程竞赛。',
        },
      }
    },
    {
      path: '/product/liteBeeWingFm/download',
      name: 'liteBeeWingFmDownload',
      component: LiteBeeWingFmDownloadVue,
      meta: {
        title: '积木无人机编队套装LiteBee Wing FM-创客火无人机',
        content: {
          keywords: '室内无人机编队，编程无人机，图形化编程，无人机整体解决方案，多机无人机表演',
          description: 'LiteBee Wing FM是一套集合4/10台的无人机室内编队飞行套装，主要面向8-16岁中小学生，可进行多机编队飞行表演，也可应用于多机编程竞赛。',
        },
      }
    },
    {
      path: '/product/liteBeeBrix',
      name: 'liteBeeBrix',
      component: LiteBeeBrixVue,
      meta: {
        title: 'DIY积木飞行器Brix-创客火无人机',
        content: {
          keywords: '积木无人机,编程无人机,创客教育',
          description: '为中小学创客教育市场量身打造，提升创造性思维而开发的STEAM教育空中机器人套件',
        },
      } 
    },
    {
      path: '/product/liteBeeBrix/qa',
      name: 'liteBeeBrixQa',
      component: LiteBeeBrixQaVue,
      meta: {
        title: 'DIY积木飞行器Brix-创客火无人机',
        content: {
          keywords: '积木无人机,编程无人机,创客教育',
          description: '为中小学创客教育市场量身打造，提升创造性思维而开发的STEAM教育空中机器人套件',
        },
      } 
    },
    {
      path: '/product/liteBeeBrix/video',
      name: 'liteBeeBrixVideo',
      component: LiteBeeBrixVideoVue,
      meta: {
        title: 'DIY积木飞行器Brix-创客火无人机',
        content: {
          keywords: '积木无人机,编程无人机,创客教育',
          description: '为中小学创客教育市场量身打造，提升创造性思维而开发的STEAM教育空中机器人套件',
        },
      } 
    },
    {
      path: '/product/liteBeeBrix/download',
      name: 'liteBeeBrixDownload',
      component: LiteBeeBrixDownloadVue,
      meta: {
        title: 'DIY积木飞行器Brix-创客火无人机',
        content: {
          keywords: '积木无人机,编程无人机,创客教育',
          description: '为中小学创客教育市场量身打造，提升创造性思维而开发的STEAM教育空中机器人套件',
        },
      } 
    },
    {
      path: '/product/liteBeeBrix3',
      name: 'liteBeeBrix3',
      component: LiteBeeBrix3Vue,
      meta: {
        title: '积木无人机LiteBee Brix III-创客火无人机',
        content: {
          keywords: '积木无人机，玩具无人机，迷你无人机，非学科类无人机，无人机教学设备',
          description: 'LiteBee Brix III是一款针对6岁以上中小学生创造性启蒙STEAM教育飞行器入门积木DIY套件，可用来学习无人机的整机结构和飞行原理。',
        },
      } 
    },
    {
      path: '/product/liteBeeBrix3/qa',
      name: 'liteBeeBrix3Qa',
      component: LiteBeeBrix3QaVue,
      meta: {
        title: '积木无人机LiteBee Brix III-创客火无人机',
        content: {
          keywords: '积木无人机，玩具无人机，迷你无人机，非学科类无人机，无人机教学设备',
          description: 'LiteBee Brix III是一款针对6岁以上中小学生创造性启蒙STEAM教育飞行器入门积木DIY套件，可用来学习无人机的整机结构和飞行原理。',
        },
      } 
    },
    {
      path: '/product/liteBeeBrix3/video',
      name: 'liteBeeBrix3Video',
      component: LiteBeeBrix3VideoVue,
      meta: {
        title: '积木无人机LiteBee Brix III-创客火无人机',
        content: {
          keywords: '积木无人机，玩具无人机，迷你无人机，非学科类无人机，无人机教学设备',
          description: 'LiteBee Brix III是一款针对6岁以上中小学生创造性启蒙STEAM教育飞行器入门积木DIY套件，可用来学习无人机的整机结构和飞行原理。',
        },
      }  
    },
    {
      path: '/product/liteBeeBrix3/download',
      name: 'liteBeeBrix3Download',
      component: LiteBeeBrix3DownloadVue,
      meta: {
        title: '积木无人机LiteBee Brix III-创客火无人机',
        content: {
          keywords: '积木无人机，玩具无人机，迷你无人机，非学科类无人机，无人机教学设备',
          description: 'LiteBee Brix III是一款针对6岁以上中小学生创造性启蒙STEAM教育飞行器入门积木DIY套件，可用来学习无人机的整机结构和飞行原理。',
        },
      }  
    },
    {
      path: '/product/armor90',
      name: 'armor90',
      component: Armor90Vue,
      meta: {
        title: '教育竞速无人机Armor 90-创客火无人机',
        content: {
          keywords: '教育无人机，无人机比赛，竞速无人机，无人机考证，无人机教学',
          description: 'Armor 90是一款为10岁以上中小学生设计开发的入手即飞教育竞速无人机，支持第一视角飞行，集教学、培训、考证和比赛于一体的多功能型无人机产品',
        },
      } 
    },
    {
      path: '/product/armor90/qa',
      name: 'armor90Qa',
      component: Armor90QaVue,
      meta: {
        title: '教育竞速无人机Armor 90-创客火无人机',
        content: {
          keywords: '教育无人机，无人机比赛，竞速无人机，无人机考证，无人机教学',
          description: 'Armor 90是一款为10岁以上中小学生设计开发的入手即飞教育竞速无人机，支持第一视角飞行，集教学、培训、考证和比赛于一体的多功能型无人机产品',
        },
      } 
    },
    {
      path: '/product/armor90/video',
      name: 'armor90Video',
      component: Armor90VideoVue,
      meta: {
        title: '教育竞速无人机Armor 90-创客火无人机',
        content: {
          keywords: '教育无人机，无人机比赛，竞速无人机，无人机考证，无人机教学',
          description: 'Armor 90是一款为10岁以上中小学生设计开发的入手即飞教育竞速无人机，支持第一视角飞行，集教学、培训、考证和比赛于一体的多功能型无人机产品',
        },
      } 
    },
    {
      path: '/product/armor90/download',
      name: 'armor90Download',
      component: Armor90DownloadVue,
      meta: {
        title: '教育竞速无人机Armor 90-创客火无人机',
        content: {
          keywords: '教育无人机，无人机比赛，竞速无人机，无人机考证，无人机教学',
          description: 'Armor 90是一款为10岁以上中小学生设计开发的入手即飞教育竞速无人机，支持第一视角飞行，集教学、培训、考证和比赛于一体的多功能型无人机产品',
        },
      } 
    },
    {
      path: '/product/liteBeeStars',
      name: 'liteBeeStars',
      component: LiteBeeStarsVue,
      meta: {
        title: '室内外低空无人机编队LiteBee Stars-创客火无人机',
        content: {
          keywords: '低空无人机编队，多机无人机表演，室内外无人机编队，无人机编程，无人机编队',
          description: 'LiteBee Stars是一套集合10-200台的无人机低空飞行表演套装，可满足室内/外无人机低空飞行表演需求。',
        },
      } 
    },
    {
      path: '/product/liteBeeStars/qa',
      name: 'liteBeeStarsQa',
      component: LiteBeeStarsQaVue,
      meta: {
        title: '室内外低空无人机编队LiteBee Stars-创客火无人机',
        content: {
          keywords: '低空无人机编队，多机无人机表演，室内外无人机编队，无人机编程，无人机编队',
          description: 'LiteBee Stars是一套集合10-200台的无人机低空飞行表演套装，可满足室内/外无人机低空飞行表演需求。',
        },
      } 
    },
    {
      path: '/product/liteBeeStars/video',
      name: 'liteBeeStarsVideo',
      component: LiteBeeStarsVideoVue,
      meta: {
        title: '室内外低空无人机编队LiteBee Stars-创客火无人机',
        content: {
          keywords: '低空无人机编队，多机无人机表演，室内外无人机编队，无人机编程，无人机编队',
          description: 'LiteBee Stars是一套集合10-200台的无人机低空飞行表演套装，可满足室内/外无人机低空飞行表演需求。',
        },
      } 
    },
    {
      path: '/product/liteBeeStars/download',
      name: 'liteBeeStarsDownload',
      component: LiteBeeStarsDownloadVue,
      meta: {
        title: '室内外低空无人机编队LiteBee Stars-创客火无人机',
        content: {
          keywords: '低空无人机编队，多机无人机表演，室内外无人机编队，无人机编程，无人机编队',
          description: 'LiteBee Stars是一套集合10-200台的无人机低空飞行表演套装，可满足室内/外无人机低空飞行表演需求。',
        },
      } 
    },
    {
      path: '/product/liteBeeSky',
      name: 'liteBeeSky',
      component: LiteBeeSkyVue,
      meta: {
        title: '编程积木无人机-创客火无人机',
        content: {
          keywords: '编程无人机，积木无人机，教育无人机，中小学无人机，diy组装，steam教育套件，迷你无人机，非学科类无人机，无人机教学设备',
          description: 'LiteBee Sky是一款针对8岁以上中小学生设计的启蒙性STEAM教育编程飞行器基础积木DIY套件。通过该产品可以学习飞行器的整机结构、飞行原理和基础编程。本套件可以DIY自由组装，搭建不同的形态，亦可进行编程学习，让孩子在动手中激发其创客性思维，在实践中发现学习的乐趣。',
        },
      } 
    },
    {
      path: '/product/liteBeeSky/qa',
      name: 'liteBeeSkyQa',
      component: LiteBeeSkyQaVue,
      meta: {
        title: '编程积木无人机-创客火无人机',
        content: {
          keywords: '编程无人机，积木无人机，教育无人机，中小学无人机，diy组装，steam教育套件，迷你无人机，非学科类无人机，无人机教学设备',
          description: 'LiteBee Sky是一款针对8岁以上中小学生设计的启蒙性STEAM教育编程飞行器基础积木DIY套件。通过该产品可以学习飞行器的整机结构、飞行原理和基础编程。本套件可以DIY自由组装，搭建不同的形态，亦可进行编程学习，让孩子在动手中激发其创客性思维，在实践中发现学习的乐趣。',
        },
      } 
    },
    {
      path: '/product/liteBeeSky/video',
      name: 'liteBeeSkyVideo',
      component: LiteBeeSkyVideoVue,
      meta: {
        title: '编程积木无人机-创客火无人机',
        content: {
          keywords: '编程无人机，积木无人机，教育无人机，中小学无人机，diy组装，steam教育套件，迷你无人机，非学科类无人机，无人机教学设备',
          description: 'LiteBee Sky是一款针对8岁以上中小学生设计的启蒙性STEAM教育编程飞行器基础积木DIY套件。通过该产品可以学习飞行器的整机结构、飞行原理和基础编程。本套件可以DIY自由组装，搭建不同的形态，亦可进行编程学习，让孩子在动手中激发其创客性思维，在实践中发现学习的乐趣。',
        },
      } 
    },
    {
      path: '/product/liteBeeSky/download',
      name: 'liteBeeSkyDownload',
      component: LiteBeeSkyDownloadVue,
      meta: {
        title: '编程积木无人机-创客火无人机',
        content: {
          keywords: '编程无人机，积木无人机，教育无人机，中小学无人机，diy组装，steam教育套件，迷你无人机，非学科类无人机，无人机教学设备',
          description: 'LiteBee Sky是一款针对8岁以上中小学生设计的启蒙性STEAM教育编程飞行器基础积木DIY套件。通过该产品可以学习飞行器的整机结构、飞行原理和基础编程。本套件可以DIY自由组装，搭建不同的形态，亦可进行编程学习，让孩子在动手中激发其创客性思维，在实践中发现学习的乐趣。',
        },
      } 
    },
    {
      path: '/product/simulator181/download',
      name: 'simulator181Download',
      component: Simulator181DownloadVue,
      meta: {
        title: '181模拟器',
        content: {
          keywords: '积木无人机,编程无人机,创客教育',
          description: '为中小学创客教育市场量身打造，提升创造性思维而开发的STEAM教育空中机器人套件',
        },
      } 
    },
    {
      path: '/product/droneBit',
      name: 'droneBit',
      component: DroneBitVue,
      meta: {
        title: '编程无人机Drone:bit-创客火无人机',
        content: {
          keywords: '编程无人机，开源无人机，无人机课程，专业级无人机，图形化编程',
          description: 'Drone:bit是一款为8-16岁青少年学生和无人机爱好者设计的编程无人机，结合了全球最火的编程开源硬件micro:bit。',
        },
      } 
    },
    {
      path: '/product/droneBit/qa',
      name: 'droneBitQa',
      component: DroneBitQaVue,
      meta: {
        title: '编程无人机Drone:bit-创客火无人机',
        content: {
          keywords: '编程无人机，开源无人机，无人机课程，专业级无人机，图形化编程',
          description: 'Drone:bit是一款为8-16岁青少年学生和无人机爱好者设计的编程无人机，结合了全球最火的编程开源硬件micro:bit。',
        },
      } 
    },
    {
      path: '/product/droneBit/video',
      name: 'droneBitVideo',
      component: DroneBitVideoVue,
      meta: {
        title: '编程无人机Drone:bit-创客火无人机',
        content: {
          keywords: '编程无人机，开源无人机，无人机课程，专业级无人机，图形化编程',
          description: 'Drone:bit是一款为8-16岁青少年学生和无人机爱好者设计的编程无人机，结合了全球最火的编程开源硬件micro:bit。',
        },
      } 
    },
    {
      path: '/product/droneBit/download',
      name: 'droneBitDownload',
      component: DroneBitDownloadVue,
      meta: {
        title: '编程无人机Drone:bit-创客火无人机',
        content: {
          keywords: '编程无人机，开源无人机，无人机课程，专业级无人机，图形化编程',
          description: 'Drone:bit是一款为8-16岁青少年学生和无人机爱好者设计的编程无人机，结合了全球最火的编程开源硬件micro:bit。',
        },
      } 
    },
    {
      path: '/product/ghostII',
      name: 'ghostII',
      component: GhostIIVue,
      meta: {
        title: '中小学编程拓展无人机Ghost-创客火无人机,编程无人机',
        content: {
          keywords: 'STEAM教育套件,scratch编程,编程无人机,教学无人机,图形化编程,无人机编程,可编程积木无人机,青少年无人机编程',
          description: 'Ghost是一款专为中小学创客教育市场量身打造，提升创造性思维而开发的STEAM教育空中机器人套件',
        },
      } 
    },
    {
      path: '/product/ghostII/qa',
      name: 'ghostIIQa',
      component: GhostIIQaVue,
      meta: {
        title: '中小学编程拓展无人机Ghost-创客火无人机,编程无人机',
        content: {
          keywords: 'STEAM教育套件,scratch编程,编程无人机,教学无人机,图形化编程,无人机编程,可编程积木无人机,青少年无人机编程',
          description: 'Ghost是一款专为中小学创客教育市场量身打造，提升创造性思维而开发的STEAM教育空中机器人套件',
        },
      } 
    },
    {
      path: '/product/ghostII/download',
      name: 'ghostIIDownload',
      component: GhostIIDownloadVue,
      meta: {
        title: '中小学编程拓展无人机Ghost-创客火无人机,编程无人机',
        content: {
          keywords: 'STEAM教育套件,scratch编程,编程无人机,教学无人机,图形化编程,无人机编程,可编程积木无人机,青少年无人机编程',
          description: 'Ghost是一款专为中小学创客教育市场量身打造，提升创造性思维而开发的STEAM教育空中机器人套件',
        },
      } 
    },
    {
      path: '/product/ghostII/video',
      name: 'ghostIIVideo',
      component: GhostIIVideoVue,
      meta: {
        title: '中小学编程拓展无人机Ghost-创客火无人机,编程无人机',
        content: {
          keywords: 'STEAM教育套件,scratch编程,编程无人机,教学无人机,图形化编程,无人机编程,可编程积木无人机,青少年无人机编程',
          description: 'Ghost是一款专为中小学创客教育市场量身打造，提升创造性思维而开发的STEAM教育空中机器人套件',
        },
      } 
    },
    {
      path: '/product/armorWasp',
      name: 'armorWasp',
      component: ArmorWaspVue,
    },
    {
      path: '/product/armorWasp/qa',
      name: 'armorWaspQa',
      component: ArmorWaspQaVue,
      meta: {
        title: '室内穿越机Armor WASP-创客火无人机',
        content: {
          keywords: '室内穿越机，迷你小四轴，无人机比赛',
          description: 'Armor WASP是一款入门级室内穿越机，采用一键两模模式，可随意切换日本手或美国手两种操作。',
        },
      }
    },
    {
      path: '/product/armorWasp/download',
      name: 'armorWaspDownload',
      component: ArmorWaspDownloadVue,
      meta: {
        title: '室内穿越机Armor WASP-创客火无人机',
        content: {
          keywords: '室内穿越机，迷你小四轴，无人机比赛',
          description: 'Armor WASP是一款入门级室内穿越机，采用一键两模模式，可随意切换日本手或美国手两种操作。',
        },
      }
    },
    {
      path: '/product/armorWasp/video',
      name: 'armorWaspVideo',
      component: ArmorWaspVideoVue,
      meta: {
        title: '室内穿越机Armor WASP-创客火无人机',
        content: {
          keywords: '室内穿越机，迷你小四轴，无人机比赛',
          description: 'Armor WASP是一款入门级室内穿越机，采用一键两模模式，可随意切换日本手或美国手两种操作。',
        },
      }
    },

    {
      path: '/product/liteBeeWing',
      name: 'liteBeeWing',
      component: LiteBeeWingVue,
      meta: {
        title: '编程积木飞行器Litebee Wing-创客火无人机',
        content: {
          keywords: '编程无人机,积木无人机,scratch编程,图形化编程,无人机课程,无人机整体解决方案',
          description: '专为8-16岁青少年设计的积木编程飞行器套件',
        },
      } 
    },
    {
      path: '/product/liteBeeWing/qa',
      name: 'liteBeeWingQa',
      component: LiteBeeWingQaVue,
      meta: {
        title: '编程积木飞行器Litebee Wing-创客火无人机',
        content: {
          keywords: '编程无人机,积木无人机,scratch编程,FPV',
          description: '专为8-16岁青少年设计的积木编程飞行器套件',
        },
      } 
    },
    {
      path: '/product/liteBeeWing/download',
      name: 'liteBeeWingDownload',
      component: LiteBeeWingDownloadVue,
      meta: {
        title: '编程积木飞行器Litebee Wing-创客火无人机',
        content: {
          keywords: '编程无人机,积木无人机,scratch编程,FPV',
          description: '专为8-16岁青少年设计的积木编程飞行器套件',
        },
      } 
    },
    {
      path: '/product/liteBeeWing/video',
      name: 'liteBeeWingVideo',
      component: LiteBeeWingVideoVue,
      meta: {
        title: '编程积木飞行器Litebee Wing-创客火无人机',
        content: {
          keywords: '编程无人机,积木无人机,scratch编程,FPV',
          description: '专为8-16岁青少年设计的积木编程飞行器套件',
        },
      } 
    },
    {
      path: '/edu/labBuild',
      name: 'labBuild',
      component: LabBuildVue,
      meta: {
        title: '创客火无人机实验室建设解决方案',
        content: {
          keywords: '无人机实验室建设，创客空间无人机夏令营，无人机活动，无人机教学，无人机科普，无人机器材，创客实验室，创客教育，创客无人机，创客平台',
          description: '创客火无人机实验室结合实际教学场景，充分考虑学校老师和学生使用需求',
        },
      } 
    },
    {
      path: '/edu/TechCourse',
      name: 'TechCourse',
      component: TechCourse,
      meta: {
        title: '无人机课程体系-创客火无人机',
        content: {
          keywords: '无人机课程，教学无人机，无人机专业课程设置，无人机兴趣课堂，中小学无人机课程，青少年无人机课程，无人机应用技术课程，无人机教学',
          description: '从初级到进阶为学校提供无人机相关课程',
        },
      } 
    },
    {
      path: '/edu/course1',
      name: 'course1',
      component: Course1Vue,
      meta: {
        title: '无人机课程体系-创客火无人机',
        content: {
          keywords: '无人机课程，教学无人机，无人机专业课程设置，无人机兴趣课堂，中小学无人机课程，青少年无人机课程，无人机应用技术课程，无人机教学',
          description: '从初级到进阶为学校提供无人机相关课程',
        },
      } 
    },
    {
      path: '/edu/course2',
      name: 'course2',
      component: Course2Vue,
      meta: {
        title: '无人机课程体系-创客火无人机',
        content: {
          keywords: '无人机课程，教学无人机，无人机专业课程设置，无人机兴趣课堂，中小学无人机课程，青少年无人机课程，无人机应用技术课程，无人机教学',
          description: '从初级到进阶为学校提供无人机相关课程',
        },
      } 
    },
    {
      path: '/edu/course3',
      name: 'course3',
      component: Course3Vue,
      meta: {
        title: '无人机课程体系-创客火无人机',
        content: {
          keywords: '无人机课程，教学无人机，无人机专业课程设置，无人机兴趣课堂，中小学无人机课程，青少年无人机课程，无人机应用技术课程，无人机教学',
          description: '从初级到进阶为学校提供无人机相关课程',
        },
      } 
    },
    {
      path: '/edu/course4',
      name: 'course4',
      component: Course4Vue,
      meta: {
        title: '无人机课程体系-创客火无人机',
        content: {
          keywords: '无人机课程，教学无人机，无人机专业课程设置，无人机兴趣课堂，中小学无人机课程，青少年无人机课程，无人机应用技术课程，无人机教学',
          description: '从初级到进阶为学校提供无人机相关课程',
        },
      } 
    },
    {
      path: '/edu/course5',
      name: 'course5',
      component: Course5Vue,
      meta: {
        title: '无人机课程体系-创客火无人机',
        content: {
          keywords: '无人机课程，教学无人机，无人机专业课程设置，无人机兴趣课堂，中小学无人机课程，青少年无人机课程，无人机应用技术课程，无人机教学',
          description: '从初级到进阶为学校提供无人机相关课程',
        },
      } 
    },
    {
      path: '/edu/psActivity',
      name: 'psActivity',
      component: PsActivityVue,
      meta: {
        title: '普及无人机只是，让孩子零距离接触无人机',
        content: {
          keywords: '科普活动,组装无人机,无人机夏令营,无人机活动,无人机教学,无人机科普,无人机器材',
          description: '让孩子学习航空知识，了解无人机发展，动手制作无人机',
        },
      } 
    },
    {
      path: '/news/list',
      name: 'news',
      component: NewsListVue,
      meta: {
        title: '最新企业及产品动态-创客火无人机',
        content: {
          keywords: '新闻中心,创客教育,最新动态,无人机赛事,无人机新课标解读,无人机培训',
          description: '创客火无人机最新动态，新产品发布信息，产品评测及用户故事等',
        },
      } 
    },
    {
      path: '/news/more',
      name: 'newsMore',
      component: NewsListMoreVue,
      meta: {
        title: '最新企业及产品动态-创客火无人机',
        content: {
          keywords: '新闻中心,创客教育,最新动态,无人机赛事,无人机新课标解读,无人机培训',
          description: '创客火无人机最新动态，新产品发布信息，产品评测及用户故事等',
        },
      } 
    },
    // {
    //   path: '/news/detail/temp',
    //   name: 'newsDetail',
    //   component: NewsDetailVue,
    // },
    {
      path: '/news/detail/*',
      name: 'newsDetail',
      component: NewsDetailVue,
      meta: {
        title: '最新企业及产品动态-创客火无人机',
        content: {
          keywords: '新闻中心,创客教育,最新动态,无人机赛事,无人机新课标解读,无人机培训',
          description: '创客火无人机最新动态，新产品发布信息，产品评测及用户故事等',
        },
      } 
    },
    {
      path: '/afterSale',
      name: 'afterSale',
      component: AfterSaleVue,
    },
    {
      path: '/download',
      name: 'download',
      component: DownloadVue,
    },
    {
      path: '/match',
      name: 'match',
      component: MatchVue, 
      meta: {
        title: '无人机比赛-创客火无人机',
        content: {
          keywords: '全国青少年无人机大赛，赛事无人机，无人机分站赛，物流搬运赛，WRC总决赛，无人机赛事，FTF青少年无人机大赛',
          description: '涵盖国家级无人机专业赛事和地方级无人机赛事，致力于打造一个专业的青少年赛事交流平台',
        },
      }
    },
    {
      path: '/',
      name: 'main',
      component: MainVue,
      // meta: {
      //   title: 'trueBag页面的title内容',
      //   content: {
      //     keywords: '这里是第二个keywords',
      //     description: '这里是第二个description',
      //   },
      // }
    }, 
  ]


}