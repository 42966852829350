




import { Component, Prop, Vue } from "vue-property-decorator";
import SubNavVue from "@/components/common/subNav/SubNav.vue";
import NewsListVue from "./NewsList.vue";

@Component({
  components: {
    "news-list": NewsListVue,
  },
})
export default class NewsListMoreVue extends Vue { 
}
