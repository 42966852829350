







































import { Component, Prop, Vue } from "vue-property-decorator";
import SubNavVue from "@/components/common/subNav/SubNav.vue";
import Axios from "axios";
import { AxiosResponse } from "axios";
import { ConfigMgr } from "../../../config";
import { CommonHelper } from "../../../helper/commonHelper";
import { HttpHelper } from "@/helper/httpHelper";

@Component({
  components: {
    "sub-nav": SubNavVue,
  },
})
export default class NewsListVue extends Vue {
  /** 是否显示加载更多 */
  @Prop({
    type: Boolean,
    default: true,
  })
  isShowGetMore?: boolean;

  subData: Array<any> = [
    {
      name: "首页",
      link: "/",
    },
    {
      name: "新闻中心",
      link: "#",
    },
  ];

  listHtml: string = "";

  count: number = 0;

  moreHtml: string = "";

  mounted() {
    HttpHelper.getJsonData("newsList").then((result: AxiosResponse) => {
      var html = result.data.html;
      this.listHtml = html;
    });
    HttpHelper.getJsonData("newsListMore").then((result: AxiosResponse) => {
      this.moreHtml = result.data.html;
      this.count = this.moreHtml.split("<li>").length - 1;
    });

    // if (this.isShowGetMore) {
    //   //是要加载更多
    //   HttpHelper.getJsonData("newsList").then((result: AxiosResponse) => {
    //     var html = result.data.html;
    //     this.listHtml = html;
    //     this.count = html.split('<li>').length - 1 ;
    //   });
    // }
    // else {
    //   HttpHelper.getJsonData("newsListMore").then((result: AxiosResponse) => {
    //     var html = result.data.html;
    //     this.listHtml = html;
    //     this.$nextTick(()=>{
    //       window.scrollTo(0,this.initScrollTop);
    //     })
    //   });
    // }
  }

  onShowMore() {
    this.isShowGetMore = false;
    this.listHtml = this.moreHtml;
  }
}
