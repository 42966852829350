










































































































import { Component, Prop, Vue } from "vue-property-decorator";
import ProduceNav from "@/components/product/ProductNav.vue";
import ProduceNavVue from "@/components/product/ProductNav.vue";
import { RouterHelper } from "../../../router/routerHelper";

@Component({
  components: {
    "product-nav": ProduceNavVue,
  },
})
export default class LiteBeeWingFmVue extends Vue {
  p2TabList: Array<any> = [
    {
      title: "编队造型丰富多变",
      desc:
        "自由选取音乐进行剪辑，设计编排无人机的舞蹈动作，通过编程让无人机结合音乐的节拍完成整套舞蹈动作",
      img: "https://cn.litebee.com/assets/local/prod/liteBeeWingFM/7.jpg",
    },
    {
      title: "精彩表演源于安全保障",
      desc: "飞行器低电或侧翻会自动保护降落，超出安全飞行范围停止飞行",
      img: "https://cn.litebee.com/assets/local/prod/liteBeeWingFM/8.jpg",
    },
  ];

  p2SwiperIndex: number = 0;

  tabList: Array<any> = [];

  swiperOption: any = {
    autoplay: 8000,
    initialSlide: 0,
    disableOnInteraction: false, // 手动切换之后继续自动轮播
    loop: false,
    onSlideChangeStart: (swiper: any) => {
      this.$nextTick(() => {
        this.changeSwiperIndex(swiper.realIndex);
      });
    },
  };

  onClickSwiperProgress(index: number) {
    this.p2Swiper.slideTo(index);
    this.changeSwiperIndex(index);
  }

  changeSwiperIndex(index: number) {
    this.p2SwiperIndex = index;
  }

  get p2Swiper() {
    return (<any>this.$refs.p2Swiper).swiper;
  }

  mounted() {
    new (<any>window).WOW().init();

    RouterHelper.getProdRouterConfig("liteBeeWingFm", (data: Array<any>) => {
      this.tabList = data;
    });
  }
}
