





































































































import { Component, Prop, Vue } from "vue-property-decorator";
import SubNavVue from "@/components/common/subNav/SubNav.vue";

@Component({
  components: {
    "sub-nav": SubNavVue,
  },
})
export default class AboutUsVue extends Vue {
  linkData: Array<any> = [
    {
      name: "首页",
      link: "/",
    },
    {
      name: "关于我们",
      link: "#",
    },
  ];
 

  mounted() {
    new (<any>window).WOW().init();
  }
}
