


























































































































import LabBuildVue from "./labBuild";
export default LabBuildVue;
