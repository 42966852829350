import { HttpHelper } from '@/helper/httpHelper';
import { AxiosResponse } from 'axios';


export class RouterHelper {


  /** 获取产品路由配置 */
  static getProdRouterConfig(name: string, callback: Function) {


    HttpHelper.getJsonData('prod').then((axios: AxiosResponse) => {
      var data: any = axios.data;
      data[name] = data[name] || {};

      if (callback) {
        if (name === 'simulator181') {
          callback([
            {
              name: " ",
              link: '/product/' + name + '/download',
            },
            {
              name: "软件下载",
              link: '/product/' + name + '/download',
            }]);
        }
        else {
          callback([
            {
              name: "教学视频",
              link: '/product/' + name + '/video',
            },
            {
              name: "软件下载",
              link: '/product/' + name + '/download',
            },
            {
              name: "常见问题",
              link: '/product/' + name + '/qa',
            },
            {
              name: "立即购买",
              link: data[name].buyLink || '',
              out: true
            },
          ])
        }

      }

    });


  }



}