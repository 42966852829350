
































































import { Component, Prop, Vue } from "vue-property-decorator";
import SubNavVue from "@/components/common/subNav/SubNav.vue";
import SubNav2Vue from "@/components/common/subNav2/SubNav2.vue";

@Component({
  components: {
    subnav: SubNavVue,
    subnav2: SubNav2Vue,
  },
})
export default class Course2Vue extends Vue {
  subData: Array<any> = [
    {
      name: "首页",
      link: "/",
    },
    {
      name: "教育方案",
      link: "#",
    },
    { 
      name: "课程体系",
      link: "#", 
    },
    {
      name: "无人机创客教育课程（初级II）",
      link: "#",
    },
  ];

  sub2Data: Array<any> = [
        {
            label: '双师课堂',
            link: '/edu/TechCourse'
        },
        {
            label: '无人机创客教育课程（初级I）',
            link: '/edu/course1'
        },
        {
            label: '无人机创客教育课程（初级II）',
            link: '/edu/course2'
        },
        {
            label: '无人机创客教育课程（进阶I）',
            link: '/edu/course3'
        },
        {
            label: '无人机创客教育课程（进阶II）',
            link: '/edu/course4'
        },
        {
            label: '无人机创客教育课程（FM编队）',
            link: '/edu/course5'
        }
    ];
}
