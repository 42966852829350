













































































































































import { Component, Prop, Provide, Vue } from "vue-property-decorator";
import Axios, { AxiosResponse } from "axios";
import { ConfigMgr } from "@/config";
import { HttpHelper } from "../../helper/httpHelper";
import MetaHelper from "../../helper/metaHelper";

@Component({})
export default class MainVue extends Vue {
  bannerList: Array<any> = [];
  bannerSwiperIndex: number = 0;

  private _tabSwiper: any;

  onClickBannerSwiperProgress(index: number) {
    this.swiperBanner.slideTo(index);
    this.bannerSwiperIndex = index;
  }

  productTabList: Array<any> = [
    {
      name: "LiteBee Brix III",
      desc: "积木无人机",
      imgs: ["https://cn.litebee.com/assets/local/main/9.jpg"],
      link: "/product/liteBeeBrix3",
    },
    {
      name: "LiteBee Wing",
      desc: "编程积木无人机",
      imgs: ['https://cn.litebee.com/assets/main/2.png'],
      link: "/product/liteBeeWing",
    },
    {
      name: "LiteBee Wing FM",
      desc: "编程积木无人机编队",
      imgs: ['https://cn.litebee.com/assets/main/1.png'],
      link: "/product/liteBeeWingFm",
    },
    {
      name: "LiteBee Stars",
      desc: "无人机编队套装",
      imgs: ["https://cn.litebee.com/assets/local/main/liteBeeStars.jpg"],
      link: "/product/liteBeeStars",
    },
    {
      name: "LiteBee Sky",
      desc: "编程积木无人机",
      imgs: ["https://cn.litebee.com/assets/local/main/liteBeeSky.jpg"],
      link: "/product/liteBeeSky",
    },
    {
      name: "Armor 90",
      desc: "教育竞速无人机",
      imgs: ["https://cn.litebee.com/assets/local/main/armor90.jpg"],
      link: "/product/armor90",
    },
  ];
  productTabIndex: number = 0;
 

  matchsList: Array<any> = [
    { img: "https://cn.litebee.com/assets/local/main/match1.jpg" },
    { img: "https://cn.litebee.com/assets/local/main/match2.jpg" },
    { img: "https://cn.litebee.com/assets/local/main/match3.jpg" },
  ];

  eduList: Array<any> = [
    {
      img: 'https://cn.litebee.com/assets/main/5.png',
      link: "/edu/labBuild",
      label: "实验室建设",
    },
    {
      img: "https://cn.litebee.com/assets/local/main/4.jpg",
      link: "/edu/TechCourse",
      label: "课程体系",
    },
    {
      img: 'https://cn.litebee.com/assets/main/3.png',
      link: "/edu/psActivity",
      label: "科普活动",
    },
  ];

  get swiperBanner() {
    return (this.$refs["bannerSwiper"] as any).swiper;
  }

  get swiperMatch() {
    return (this.$refs["matchSwiper"] as any).swiper;
  }

   get swiperEdu() {
    return (this.$refs["eduSwiper"] as any).swiper;
  }


  swiperBannerOption: any = {
    autoplay: 6000,
    initialSlide: 0, 
    disableOnInteraction: false, // 手动切换之后继续自动轮播
    loop: false,
    onSlideChangeStart: (swiper: any) => {
      this.$nextTick(() => {
        this.changeBannerSwiperIndex(swiper.realIndex);
      });
    },
  };

  swiperMatchOption: any = {
    autoplay: 6000,
    initialSlide: 0,
    disableOnInteraction: false, // 手动切换之后继续自动轮播
    loop: false,
    onSlideChangeEnd: (swiper: any) => {},
  };

  swiperEduOption: any = {
    autoplay: 6000,
    slidesPerView: 2,
    loop: false,
    disableOnInteraction: false, // 手动切换之后继续自动轮播
  };

  changeBannerSwiperIndex(index: number) {
    this.bannerSwiperIndex = index;
  }

  onClickProductTab(index: number) {
    this.productTabIndex = index;
  }

  onClickProductSwiperImg(pre: boolean) {
    if (pre) {
      this._tabSwiper.slidePrev();
    } else {
      this._tabSwiper.slideNext();
    }
  }

  onClickMatchSwiperImg(pre: boolean) {
    if (pre) {
      this.swiperMatch.slidePrev();
    } else {
      this.swiperMatch.slideNext();
    }
  }

  onClickEduSwiperImg(pre: boolean) {
    if (pre) {
      this.swiperEdu.slidePrev();
    } else {
      this.swiperEdu.slideNext();
    }
  }

  mounted() {
    HttpHelper.getJsonData("main").then((result: AxiosResponse) => {
      this.bannerList = result.data.banner || []; 
    });
    this._tabSwiper = new (window as any).Swiper(".swiperTab", {
      autoplay: 4000, //可选选项，自动滑动
      loop: false,
    });

    // document.title = 'main' ;
  }
}
