
































































import { Component, Prop, Vue } from "vue-property-decorator";
import ProduceNavVue from "@/components/product/ProductNav.vue";
import SubNavVue from "@/components/common/subNav/SubNav.vue";
import Axios, { AxiosResponse } from "axios";
import { ConfigMgr } from "@/config";
import { HttpHelper } from "@/helper/httpHelper";

@Component({
  components: {
    "sub-nav": SubNavVue,
  },
})
export default class NewsDetailVue extends Vue {
  subData: Array<any> = [
    {
      name: "首页",
      link: "/",
    },
    {
      name: "新闻中心",
      link: "/news/list",
    },
    {
      name: "媒体报道",
      link: "#",
    },
  ];

  htmlStr: string = "";

  mounted() {
    var paths: Array<string> = window.location.href.split("/");
    var name: string = paths[paths.length - 1];
    if (!name) {
      name = paths[paths.length - 2];
    }
    HttpHelper.getJsonData("newsDetail/" + name).then(
      (result: AxiosResponse) => {
        var html = result.data.html;
        this.htmlStr = html;
      }
    );
  }
}
