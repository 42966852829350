import { Component, Prop, Vue } from "vue-property-decorator";
import SubNavVue from '../../../components/common/subNav/SubNav.vue'; 
import SubNav2Vue from '../../../components/common/subNav2/SubNav2.vue';

@Component({
    components: {
        'subnav': SubNavVue,
        'subnav2': SubNav2Vue
    }
})
export default class LabBuildVue extends Vue {

    subData: Array<any> = [
        {
            name: "首页",
            link: "/",
        },
        {
            name: "教育方案",
            link: "#",
        },
        {
            name: "实验室建设系",
            link: "#",
        },
        {
            name: "中小学实验室方案",
            link: "#",
        },
    ];

    sub2Data: Array<any> = [
        {
            label: '中小学实验室方案',
            link: ''
        } 
    ];

    
}