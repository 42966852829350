




























































































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import SubNavVue from "@/components/common/subNav/SubNav.vue";

@Component({
  components: {
    "sub-nav": SubNavVue,
  },
})
export default class MatchVue extends Vue {
  subData: Array<any> = [
    {
      name: "首页",
      link: "/",
    },
    {
      name: "赛事体系",
      link: "#",
    },
    {
      name: "无人机竞赛",
      link: "",
    },
  ];
}
