









import { Component, Prop, Vue } from "vue-property-decorator";
import QaVue from "../qa/Qa.vue";
import { RouterHelper } from "@/router/routerHelper";
import { HttpHelper } from "@/helper/httpHelper";
import { AxiosResponse } from "axios";

@Component({
  components: {
    qa: QaVue,
  },
})
export default class LiteBeeProQaVue extends Vue {
  prodName: string = "LiteBee Pro";
  prodCode: string = "liteBeePro";

  tabList: Array<any> = [] ;
  linkData: Array<any> = [
    { name: "首页", link: "/" },
    { name: this.prodName, link: "/product/liteBeePro" },
    { name: "常见问题", link: "#" },
  ];

  mounted() {
    RouterHelper.getProdRouterConfig(this.prodCode, (data: Array<any>) => {
      this.tabList = data;
    });
  }
}
